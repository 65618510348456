var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        [
          _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
          _c(
            "div",
            {
              staticStyle: { overflow: "scroll" },
              attrs: { id: "body-content-area-all" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "has-text-centered heading",
                  style: { backgroundColor: "#ededed", marginBottom: "0px" },
                },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "is-size-6",
                      staticStyle: { padding: "10px 0px" },
                    },
                    [
                      _c("b", [
                        _vm._v(_vm._s(_vm.pageName.toLocaleUpperCase())),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: { padding: "25px" },
                  attrs: { id: "pagelayout" },
                },
                [
                  _c(
                    "small",
                    {
                      staticStyle: {
                        color: "#eaae5e!important",
                        padding: "5px",
                        "margin-top": "20px",
                      },
                    },
                    [_vm._v("Please use a modern browser to upload files.")]
                  ),
                  _vm.documents
                    ? _c("Grid", {
                        staticStyle: { height: "100%" },
                        attrs: {
                          filter: _vm.filter,
                          "data-items": _vm.documents,
                          sortable: true,
                          sort: _vm.sort,
                          filterable: false,
                          columns: _vm.staticColumns,
                          skip: _vm.skip,
                          take: _vm.take,
                          total: _vm.totalRecords,
                          pageable: _vm.pageable,
                        },
                        on: {
                          selectionchange: _vm.selectionchange,
                          headerselectionchange: _vm.headerselectionchange,
                          filterchange: _vm.filterchange,
                          sortchange: _vm.sortchange,
                          pagechange: _vm.pagechange,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "NameCell",
                              fn: function (ref) {
                                var props = ref.props
                                return [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "editable",
                                      staticStyle: {
                                        "text-overflow": "clip!important",
                                      },
                                    },
                                    [
                                      _vm.isOwnedByUser(props.dataItem)
                                        ? _c("input", {
                                            attrs: { type: "text" },
                                            domProps: {
                                              value: props.dataItem.displayName,
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.submit(
                                                  $event,
                                                  props.dataItem,
                                                  "displayName"
                                                )
                                              },
                                              keyup: [
                                                function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "esc",
                                                      27,
                                                      $event.key,
                                                      ["Esc", "Escape"]
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.reset(
                                                    $event,
                                                    props.dataItem.displayName
                                                  )
                                                },
                                                function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.submit(
                                                    $event,
                                                    props.dataItem,
                                                    "displayName"
                                                  )
                                                },
                                              ],
                                            },
                                          })
                                        : _c("input", {
                                            attrs: { type: "text" },
                                            domProps: {
                                              value: props.dataItem.displayName,
                                            },
                                            on: {
                                              mousedown: function ($event) {
                                                $event.preventDefault()
                                              },
                                            },
                                          }),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "TypeCell",
                              fn: function (ref) {
                                var props = ref.props
                                return [
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          props.dataItem.extension
                                            .replace(".", "")
                                            .toUpperCase()
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "SizeCell",
                              fn: function (ref) {
                                var props = ref.props
                                return [
                                  _c(
                                    "td",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getFileSize(
                                              props.dataItem.fileSize
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "UploadDateCell",
                              fn: function (ref) {
                                var props = ref.props
                                return [
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("moment")(
                                            props.dataItem.uploadDate,
                                            "timezone",
                                            _vm.getTimeZone(),
                                            "MM/DD/YYYY"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "UploadedByCell",
                              fn: function (ref) {
                                var props = ref.props
                                return [
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(props.dataItem.uploadUserName) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "SharingCell",
                              fn: function (ref) {
                                var props = ref.props
                                return [
                                  _vm.isOwnedByUser(props.dataItem)
                                    ? _c("td", { staticClass: "editable" }, [
                                        _c(
                                          "select",
                                          {
                                            on: {
                                              change: function ($event) {
                                                return _vm.submit(
                                                  $event,
                                                  props.dataItem,
                                                  "isShared"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: true,
                                                  selected:
                                                    props.dataItem.isShared,
                                                },
                                              },
                                              [_vm._v("Shared")]
                                            ),
                                            _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: false,
                                                  selected:
                                                    !props.dataItem.isShared,
                                                },
                                              },
                                              [_vm._v("Private")]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _c("td", { staticClass: "editable" }, [
                                        _c(
                                          "select",
                                          {
                                            ref: "isSharedDropdown",
                                            on: {
                                              mousedown: function ($event) {
                                                $event.preventDefault()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { domProps: { selected: true } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    props.dataItem.isShared
                                                      ? "Shared"
                                                      : "Private"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                ]
                              },
                            },
                            {
                              key: "ActionCell",
                              fn: function (ref) {
                                var props = ref.props
                                return [
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        padding: "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "action-cell" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName:
                                                    "v-tooltip.left-start",
                                                  value: {
                                                    content:
                                                      "Download Document",
                                                    container: false,
                                                    classes: ["tooltip"],
                                                  },
                                                  expression:
                                                    "{ content: 'Download Document', container: false, classes: ['tooltip'] }",
                                                  modifiers: {
                                                    "left-start": true,
                                                  },
                                                },
                                              ],
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: _vm.getDocumentPath(
                                                      props.dataItem
                                                    ),
                                                    download: "",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-download",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName:
                                                    "v-tooltip.left-start",
                                                  value: {
                                                    content: "View Document",
                                                    container: false,
                                                    classes: ["tooltip"],
                                                  },
                                                  expression:
                                                    "{ content: 'View Document', container: false, classes: ['tooltip'] }",
                                                  modifiers: {
                                                    "left-start": true,
                                                  },
                                                },
                                              ],
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: { href: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.viewDocument(
                                                        props.dataItem
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-eye",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName:
                                                    "v-tooltip.left-start",
                                                  value: {
                                                    content: "Delete Document",
                                                    container: false,
                                                    classes: ["tooltip"],
                                                  },
                                                  expression:
                                                    "{ content: 'Delete Document', container: false, classes: ['tooltip'] }",
                                                  modifiers: {
                                                    "left-start": true,
                                                  },
                                                },
                                              ],
                                            },
                                            [
                                              _vm.isOwnedByUser(props.dataItem)
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: { href: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.deleteDocument(
                                                            props.dataItem
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-trash",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          630401328
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.showDeleteModal
                ? _c(
                    "div",
                    {
                      staticClass: "modal is-active",
                      attrs: { id: "delete-modal" },
                    },
                    [
                      _c("div", {
                        staticClass: "modal-background",
                        on: {
                          click: function ($event) {
                            _vm.showDeleteModal = false
                          },
                        },
                      }),
                      _c("div", { staticClass: "modal-card" }, [
                        _c(
                          "header",
                          { staticClass: "modal-card-head has-bg-danger" },
                          [
                            _c(
                              "div",
                              { staticClass: "modal-card-title has-bg-danger" },
                              [_vm._v("Confirm Delete")]
                            ),
                            _c("a", {
                              staticClass: "delete",
                              attrs: { "aria-label": "close" },
                              on: {
                                click: function ($event) {
                                  _vm.showDeleteModal = false
                                },
                              },
                            }),
                          ]
                        ),
                        _c("section", { staticClass: "modal-card-body" }, [
                          _c("div", { staticClass: "content" }, [
                            _vm._v(' Type "'),
                            _c("b", [_vm._v("delete")]),
                            _vm._v(
                              '" to confirm that you want to delete "' +
                                _vm._s(_vm.selectedFile.name) +
                                '" '
                            ),
                          ]),
                          _c("div", { staticClass: "field" }, [
                            _c("div", { staticClass: "control" }, [
                              _c("input", {
                                directives: [
                                  { name: "focus", rawName: "v-focus" },
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.confirmationText,
                                    expression: "confirmationText",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass: "input",
                                attrs: {
                                  type: "text",
                                  placeholder:
                                    "type `delete` and then click confirm",
                                },
                                domProps: { value: _vm.confirmationText },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.preventDefault()
                                    return _vm.deleteIfConfirmed.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.confirmationText =
                                      $event.target.value.trim()
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                        _c("footer", { staticClass: "modal-card-foot" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button is-danger",
                              attrs: {
                                disabled: _vm.confirmationText !== "delete",
                              },
                              on: { click: _vm.deleteIfConfirmed },
                            },
                            [_vm._m(0), _c("span", [_vm._v("Confirm")])]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "button",
                              on: {
                                click: function ($event) {
                                  _vm.showDeleteModal = false
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    : _c("div", [_c("Loading")], 1)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }